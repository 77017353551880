import {get, post, download, put} from "../index"


/**
 * 获取地区省份列表
 * @param data
 * @returns {Promise<any | never>}
 */
export const getSystemRegion = data => {
    return get("/system/region/province", data).then(res => res);
}

/**
 * 获取地区城市列表
 * @param data
 * @returns {Promise<any | never>}
 */
export const getSystemRegionCity = data => {
    return get("/system/region/children/" + data).then(res => res);
}

/**
 * 获取地区 区列表
 * @param data
 * @returns {Promise<any | never>}
 */
export const getSystemRegionCounty = data => {
    return get("/system/region/children/" + data).then(res => res);
}
